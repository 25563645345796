// Generated by Framer (ccd740c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KShbMjvsF"];

const variantClassNames = {KShbMjvsF: "framer-v-19m5m02"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 300, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "KShbMjvsF", link: VQzYh2HaC, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KShbMjvsF", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8JMsw", classNames)} style={{display: "contents"}}>
<Link href={VQzYh2HaC} openInNewTab><motion.a {...restProps} className={`${cx("framer-19m5m02", className)} framer-74sn06`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KShbMjvsF"} ref={ref} style={{...style}} transition={transition}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8JMsw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8JMsw * { box-sizing: border-box; }", ".framer-8JMsw .framer-74sn06 { display: block; }", ".framer-8JMsw .framer-19m5m02 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; min-height: 53px; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 165px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8JMsw .framer-19m5m02 { gap: 0px; } .framer-8JMsw .framer-19m5m02 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-8JMsw .framer-19m5m02 > :first-child { margin-left: 0px; } .framer-8JMsw .framer-19m5m02 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 165
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"VQzYh2HaC":"link"}
 */
const Framerxxs6dJmhp: React.ComponentType<Props> = withCSS(Component, css, "framer-8JMsw") as typeof Component;
export default Framerxxs6dJmhp;

Framerxxs6dJmhp.displayName = "Lottie / Next";

Framerxxs6dJmhp.defaultProps = {height: 53, width: 165};

addPropertyControls(Framerxxs6dJmhp, {VQzYh2HaC: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerxxs6dJmhp, [])